.App-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
.App-footer {
  border-top-color: #9b2cac;
  border-top-style: solid;
  border-top-width: 1px;
  margin-top: 1.5rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-footer-link {
  color: #9b2cac;
}